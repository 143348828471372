

















































































































































import { useMinByDate } from "@/hooks";
import { ONE } from "@/models/constant/global.constant";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import {
  AssetBookTypeEnum,
  AssetStateEnum,
} from "@/models/enums/master-asset.enum";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetAdjustmentService } from "@/services/asset-adjustment.service";
import { assetsServices } from "@/services/assets.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  numericOnly,
  numericOnlyv2,
} from "@/validator/globalvalidator";
import { AssetAdjustmentFormDto } from "@interface/asset";
import moment, { Moment } from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "AdjustmentFinancial",
  data() {
    return {
      Mode,
      DEFAULT_DATE_FORMAT,
      getLeasingValue: false as boolean,
      disabledSave: false as boolean,
      formatCurrency: currencyFormat,
      formatCurrencytoNumber: changeCurrencytoNumeric,
      formatNumericOnly: numericOnly,
      mode: "" as string,
      idReferenceNumber: "" as string,
      form: this.$form.createForm(this, { name: "adjustmentFinancial" }),
      isFormSubmitted: false as boolean,
      dataListBook: [] as any[],
      loadingListAssetBook: false as boolean,
      disabledBook: false,
      dataResponseFinancial: [] as any[],
      formRules: {
        assetType: {
          label: "lbl_asset_type",
          name: "assetType",
          decorator: ["assetType"],
        },
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number",
          decorator: [
            "assetNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_book",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unit: {
          label: "lbl_quantity",
          name: "unit",
          placeholder: "lbl_quantity",
          decorator: [
            "unit",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        currentCost: {
          label: "lbl_current_cost",
          name: "currentCost",
          placeholder: "lbl_current_cost",
          decorator: [
            "currentCost",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                { validator: numericOnlyv2 },
              ],
            },
          ],
        },
        originalCost: {
          label: "lbl_original_cost",
          name: "originalCost",
          placeholder: "lbl_original_cost",
          decorator: [
            "originalCost",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        acquisitionDate: {
          label: "lbl_acquisition_date",
          name: "acquisitionDate",
          placeholder: "lbl_acquisition_date",
          decorator: [
            "acquisitionDate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        receiveDate: {
          label: "lbl_receive_date",
          name: "receiveDate",
          placeholder: "lbl_receive_date",
          decorator: ["receiveDate"],
        },
        method: {
          label: "lbl_method",
          name: "method",
          placeholder: "lbl_method",
          decorator: [
            "method",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        lifeMonths: {
          label: "lbl_life_months",
          name: "lifeMonths",
          placeholder: "lbl_life_months",
          decorator: [
            "lifeMonths",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        depreciate: {
          label: "lbl_depreciate",
          name: "depreciate",
          placeholder: "lbl_depreciate",
          decorator: [
            "depreciate",
            {
              valuePropName: "checked",
            },
          ],
        },
      },
    };
  },
  created() {
    this.mode = this.$route.meta.mode;
  },
  mounted() {
    switch (this.mode) {
      case Mode.EDIT:
        this.getDetailData();
        break;
      case Mode.VIEW:
        this.idReferenceNumber = decodeURIComponent(this.$route.params.id);
        this.disabledBook = true;
        this.getDataViewFinancial();
        break;
      default:
        break;
    }
  },
  methods: {
    moment,
    onBlur(value, form) {
      const price = value.target.value;
      if (price && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as any;
        // min price is 1
        if (parseInt(price) >= ONE) {
          dataobj[form] = this.formatCurrency(value.target.value);
        } else {
          dataobj[form] = this.formatCurrency(ONE);
        }
        this.form.setFieldsValue(dataobj);
      }
    },
    onFocus(value, form) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as any;
        dataobj[form] = this.formatCurrencytoNumber(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    handleCancel(): void {
      this.form.resetFields();
      this.$router.push("/inquiry/find");
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getDataViewFinancial() {
      let params = {
        page: 0,
        limit: 10,
        search: `referenceNo~${this.idReferenceNumber}`,
      } as RequestQueryParamsModel;
      this.loadingListAssetBook = true;
      assetAdjustmentService.detailAssetAdjustment(params).then(data => {
        this.dataListBook.push({
          id: data[0].financial.assetBook.id,
          name: data[0].financial.assetBook.name,
        });
        this.form.setFieldsValue({
          currentCost: data[0].financial.originalCost,
          assetNumber: data[0].financial.assetNumber,
          book: data[0].financial.assetBook?.id
            ? data[0].financial.assetBook?.id
            : null,
          lifeMonths: data[0].financial.lifeMonths,
          unit: data[0].financial.unit,
          method: data[0].financial.bookMethod
            ? data[0].financial.bookMethod
            : null,
          originalCost: data[0].financial.originalCost,
          acquisitionDate: data[0].financial.acquisitionDate,
        });
      });
    },
    getDetailData(): void {
      this.dataResponseFinancial = [];
      assetsServices
        .listAssetAdjustmentFormById({}, this.$route.params.id)
        .then((data: AssetAdjustmentFormDto) => {
          this.getLeasingValue = data.leasing;
          if (
            !data.leasing ||
            data.assetStatus.toUpperCase() ===
              AssetStateEnum.RETIRED.toUpperCase()
          ) {
            this.disabledBook = true;
            this.form.setFieldsValue({
              book: null,
            });
          }
          if (
            data.assetStatus.toUpperCase() ===
            AssetStateEnum.RETIRED.toUpperCase()
          ) {
            this.idReferenceNumber = "found";
            this.disabledSave = true;
          } else {
            this.idReferenceNumber = "";
            this.disabledSave = false;
          }
          data.financial.forEach(element => {
            this.dataResponseFinancial.push(element);
            this.dataListBook.push({
              id: element.assetBookRelationId,
              name: element.assetBook,
            });
          });
          const found = data.financial.find(
            item =>
              item.type.toUpperCase() ===
              AssetBookTypeEnum.COMMERCIAL.toUpperCase()
          );
          this.form.setFieldsValue({
            book: found?.assetBookRelationId || "",
            assetType: data.assetType,
            acquisitionDate: found ? moment(found.acqDate) : null,
            receiveDate: data.receiveDate ? moment(data.receiveDate) : null,
            currentCost: found
              ? this.formatCurrency(found.currentCostAsset)
              : null,
            assetNumber: data.assetNo,
            lifeMonths: found?.currentLifeMonth || 0,
            unit: data.quantity,
            method: data.method,
            originalCost: data.originalCost
              ? this.formatCurrency(data.originalCost)
              : null,
            depreciate: found?.depreciate || false,
          });
        });
    },
    changeBook(value: string) {
      const dtFinancial = this.dataResponseFinancial.find(
        item => item.assetBookRelationId === value
      );
      this.form.setFieldsValue({
        depreciate: dtFinancial?.depreciate || false,
        currentCost: dtFinancial
          ? this.formatCurrency(dtFinancial.currentCostAsset)
          : "",
        lifeMonths: dtFinancial?.currentLifeMonth || 0,
        acquisitionDate: dtFinancial ? moment(dtFinancial.acqDate) : null,
      });
    },
    submitForm(e: Event): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const dataPost = {
            assetBookRelationId: !this.getLeasingValue ? null : values.book,
            cost: this.formatCurrencytoNumber(values.currentCost),
            lifeMonth: values.lifeMonths,
            acquisitionDate: this.moment(values.acquisitionDate).format(
              DATE_FORMAT_YYYY_MM_DD
            ),
          };
          this.isFormSubmitted = true;
          assetsServices
            .updateAssetAdjustmentFinancial(dataPost, this.$route.params.id)
            .then(() => {
              this.form.resetFields();
              this.$notification.success({
                description: Messages.CREATE_SUCCESS,
                message: "Success",
                duration: 30,
              });

              this.$router.push("/inquiry/find");
            })
            .catch(error => {
              this.isFormSubmitted = false;
              this.$notification.error({
                description: Messages.CREATE_FAIL,
                message: "Error",
                duration: 30,
              });
              this.$notification.error({
                description: error.details,
                message: "Error",
                duration: 30,
              });
            })
            .finally(() => (this.isFormSubmitted = false));
        } else {
          this.$notification["error"]({
            message: "Error",
            description: "Form is mandatory",
          });
        }
      });
    },
    isLessThanReceiveDate(curr: Moment) {
      const receiveDate = this.form.getFieldValue("receiveDate");
      if (receiveDate) return useMinByDate(curr, receiveDate);
      return false;
    },
  },
});
